<template>
  <Dialog style="width:90%;height:100%;" v-model:visible="display" @hide="close()">
    <Toast/>
    <template #header>
      <h3>{{ title }}文章</h3>
    </template>
    <div class="p-flex-column">
      <label>标题：</label>
      <InputText @blur="change" style="width:100%;" v-model="from.title"/>
      <span class="change">{{ mas }}</span>
    </div>
    <div class="p-flex-column p-mt-4">
      <label>副标题：</label>
      <InputText style="width:100%;" v-model="from.subheading"/>
    </div>
    <div class="p-flex-column p-mt-4">
      <label>所属：</label>
      <Dropdown @blur="change1" style="width:100%;" v-model="from.belong" @change="onchange()"
                :options="belongList" optionLabel="belong" optionValue="value"
                placeholder="选择类别"/>
      <span class="change">{{ mas1 }}</span>
    </div>
    <div class="p-flex-column p-mt-4">
      <label>类别：</label>
      <MultiSelect @blur="change2" style="width:100%;" v-model="from.columnId" :options="columnIdList"
                   optionLabel="title" optionValue="id" placeholder="选择类别"/>
      <span class="change">{{ mas2 }}</span>
    </div>
    <div class="p-mt-4">
      <label>简介：</label>
      <Textarea @blur="change3" style="width:100%;" v-model="from.brief"/>
      <span class="change">{{ mas3 }}</span>
    </div>
    <div class="p-flex-column p-mt-4">
      <label>作者：</label>
      <Dropdown v-model="from.author" :options="userList" optionLabel="userName" option-value="id" placeholder="请选择专家"/>
      <!--      <InputText style="width:100%;" v-model="from.author"/>-->
    </div>
    <div class="p-flex-column p-mt-4">
      <label>来源：</label>
      <InputText style="width:100%;" v-model="from.source"/>
    </div>
    <div class="p-d-flex p-mt-4">
      <label class="p-mt-2">主图：</label>
      <div>
        <FileUpload v-model="from.pic" name="file" :url="fileUploadUrl"
                    @upload="onUpload" @select="selectPic" auto="true" mode="basic" accept="image/*"/>
        <img class="p-mt-2" style="width: 180px" :src="picUrl">
      </div>
    </div>
    <div class="p-mt-2">
      <label>内容：</label>
      <div class="p-mt-2">
        <my-editor :value="from.content" v-on:input="setContent"></my-editor>
        <span class="change">{{ mas4 }}</span>
      </div>
    </div>
    <div class="p-grid p-col-12 p-mt-6 p-jc-around">
      <Button label="取消" @click="display=false" class="p-mr-2" icon="pi pi-fw pi-replay"/>
      <Button label="保存并发布" class="p-mr-2" icon="pi pi-fw pi-send" @click="submit"/>
    </div>
  </Dialog>
</template>

<script>
import MyEditor from "@/views/backend/myEditor";

export default {
  name: "",
  components: {MyEditor},
  data() {
    return {
      mas: null,
      mas1: null,
      mas2: null,
      mas3: null,
      mas4: null,
      title: false,
      display: false,
      fileUploadUrl: this.$global_msg.frontendUrl + "/file/uploadOne",

      from: {
        id: null,
        title: null,
        content: null,
        brief: null,
        author: null,
        source: null,
        pic: null,
        subheading: null,
        belong: null,
        columnId: null
      },
      belongList: [{belong: '网站', value: 1}, {belong: '公众号', value: 2}],
      columnIdList: [],
      picUrl: null,
      userList: []
    }
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    getUserList() {
      this.$http.get('/publicUser/getExpertList').then((res) => {
        this.userList = res.data
        let admin={userName:'管理员',id:0}
        this.userList.unshift(admin)
        console.log(this.userList)
      })
    },
    setContent(val) {
      this.from.content = val
    },
    change(e) {
      let b = e.target.value.replace(/^ +| +$/g, '')
      if (b != e.target.value) {
        this.mas = "您输入格式有误，请重新输入"
      } else {
        this.mas = null;
      }
    },
    change1() {
      this.mas1 = null;
    },
    change2() {
      this.mas2 = null;
    },
    change3() {
      this.mas3 = null;
    },
    init(info) {
      this.display = true
      if (info != null) {
        this.title = "修改"

        let ths = this;
        this.$http.get('/article/getOneArticle', {params: {id: info.id}}).then((res) => {

          let columnList = res.data.columnId.substring(0, res.data.columnId.lastIndexOf(',')).split(',')
          for (let i = 0; i < columnList.length; i++) {
            columnList[i] = parseInt(columnList[i])
          }

          ths.from = res.data
          this.from.columnId = columnList
          this.picUrl = this.$global_msg.imgurl + res.data.pic
        })
        this.onchange(info.belong)
      } else {
        this.title = "添加"
      }
    },
    close() {
      this.$emit('close', false)
    },
    test() {

    },
    onchange(belong) {
      this.$http.get("/article/getColumnList", {
        params: {
          belongId: belong ? belong : this.from.belong
        }
      }).then((res) => {
        this.columnIdList = res.data
      })
    },
    selectPic(event) {
      this.picUrl = event.files[0].objectURL
    },
    onUpload(event) {
      this.from.pic = event.xhr.response
      if(event.xhr.response.indexOf('static')!=-1){
        this.$toast.add({severity: 'info', summary: '成功', detail: '文件上传成功', life: 3000})
      }else {
        this.$toast.add({severity: 'error', summary: '失败', detail: '上传失败', life: 3000})
      }
    },
    submit() {
      (this.from.title == null | this.from.title == "") ? this.mas = "必填项，请输入" : this.mas = null;
      (this.from.belong == null | this.from.belong == "") ? this.mas1 = "必填项，请选择" : this.mas1 = null;
      (this.from.columnId == null | this.from.columnId == "") ? this.mas2 = "必填项，请选择" : this.mas2 = null;
      (this.from.brief == null | this.from.brief == "") ? this.mas3 = "必填项，请输入" : this.mas3 = null;
      (this.from.content == null | this.from.content == "") ? this.mas4 = "必填项，请输入" : this.mas4 = null;
      if (this.from.title != null & this.from.title != ""
          & this.from.belong != null & this.from.belong != ""
          & this.from.columnId != null & this.from.columnId != ""
          & this.from.brief != null & this.from.brief != ""
          & this.from.content != null & this.from.content != "") {

        let str = '';
        console.log(this.from.columnId)
        for (let i = 0; i < this.from.columnId.length; i++) {
          str = str + this.from.columnId[i] + ','
        }
        this.from.columnId = str
        this.$http.post(this.from.id ? '/article/updateArticle' : '/article/addArticle', this.from).then(() => {
          this.display = false
          this.$toast.add({severity: 'info', summary: '成功', detail: '操作成功', life: 3000})
          this.$emit('close', true)
        })
      }
    }
  }
}
</script>

<style scoped>
.change {
  color: red;
}
</style>
